import React from 'react'
import ProgressBar from 'react-customizable-progressbar'
import '../assets/css/progress-bar.css'

function Skills() {

    const colorTallCircle = "#EBF0F3";
    const colorSmallCircle = "#4B5A60";

    return (
        <section id='skills'>
            <div class="section-heading text-center"
                data-aos="zoom-out-up">
                <h2 class="text-light pt-4"><b>My Skills</b></h2>
                <p class="text-light">A percentage cannot describe constant learning  <i class="bi bi-emoji-smile"></i>
                </p>
            </div>
            <div class="container-fluid">
                <div class="row text-light justify-content-md-center">
                    <div class="col-md-4 col-sm-12 mt-4" align="center">
                        <hr />
                        <ProgressBar
                            radius={100}
                            progress={100}
                            initialAnimation
                            initialAnimationDelay={10}
                            strokeWidth={4}
                            strokeColor={colorTallCircle}
                            trackStrokeWidth={4}
                            pointerRadius={8}
                            pointerStrokeWidth={5}
                            pointerStrokeColor={colorSmallCircle}
                        >
                            <div className='indicator'
                                data-aos="flip-left">
                                <b>
                                    JavaScript
                                </b>
                            </div>
                        </ProgressBar>
                        <div class="row">
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="200">
                                        <b>
                                            Angular
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="400">
                                        <b>
                                            React
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="600">
                                        <b>
                                            SailsJS
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="800">
                                        <b>
                                            NodeJS
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-12 mt-4" align="center">
                        <hr />
                        <ProgressBar
                            radius={100}
                            progress={100}
                            initialAnimation
                            initialAnimationDelay={10}
                            strokeWidth={4}
                            strokeColor={colorTallCircle}
                            trackStrokeWidth={4}
                            pointerRadius={8}
                            pointerStrokeWidth={5}
                            pointerStrokeColor={colorSmallCircle}
                        >
                            <div className='indicator'
                                data-aos="flip-left">
                                <b>
                                    Java
                                </b>
                            </div>
                        </ProgressBar>
                        <div class="row">
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="200">
                                        <b>
                                            SpringBoot
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="400">
                                        <b>
                                            SpringBoot Security
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                            <div class="col-xl col-lg-6 col-sm-12">
                                <ProgressBar
                                    radius={50}
                                    progress={100}
                                    strokeWidth={12}
                                    strokeColor={colorSmallCircle}
                                    trackStrokeWidth={8}
                                    trackStrokeColor={colorSmallCircle}
                                    pointerRadius={0}
                                    initialAnimation={true}
                                >
                                    <div className='indicator'
                                        data-aos="flip-right"
                                        data-aos-delay="600">
                                        <b>
                                            SpringWebFlux
                                        </b>
                                    </div>
                                </ProgressBar>
                            </div>
                        </div>
                    </div>

                        <div class="col-md-4 col-sm-12 mt-4" align="center">
                            <hr />
                            <ProgressBar
                                radius={100}
                                progress={100}
                                initialAnimation
                                initialAnimationDelay={10}
                                strokeWidth={4}
                                strokeColor={colorTallCircle}
                                trackStrokeWidth={4}
                                pointerRadius={8}
                                pointerStrokeWidth={5}
                                pointerStrokeColor={colorSmallCircle}
                            >
                                <div className='indicator'
                                    data-aos="flip-left">
                                    <b>
                                        HTML & CSS
                                    </b>
                                </div>
                            </ProgressBar>
                            <div class="row">
                                <div class="col-xl col-lg-6 col-sm-12">
                                    <ProgressBar
                                        radius={50}
                                        progress={100}
                                        strokeWidth={12}
                                        strokeColor={colorSmallCircle}
                                        trackStrokeWidth={8}
                                        trackStrokeColor={colorSmallCircle}
                                        pointerRadius={0}
                                        initialAnimation={true}
                                    >
                                        <div className='indicator'
                                            data-aos="flip-right"
                                            data-aos-delay="200">
                                            <b>
                                                Thymeleaf
                                            </b>
                                        </div>
                                    </ProgressBar>
                                </div>
                                <div class="col-xl col-lg-6 col-sm-12">
                                    <ProgressBar
                                        radius={50}
                                        progress={100}
                                        strokeWidth={12}
                                        strokeColor={colorSmallCircle}
                                        trackStrokeWidth={8}
                                        trackStrokeColor={colorSmallCircle}
                                        pointerRadius={0}
                                        initialAnimation={true}
                                    >
                                        <div className='indicator'
                                            data-aos="flip-right"
                                            data-aos-delay="400">
                                            <b>
                                                Bootstrap
                                            </b>
                                        </div>
                                    </ProgressBar>
                                </div>
                                <div class="col-xl col-lg-6 col-sm-12">
                                    <ProgressBar
                                        radius={50}
                                        progress={100}
                                        strokeWidth={12}
                                        strokeColor={colorSmallCircle}
                                        trackStrokeWidth={8}
                                        trackStrokeColor={colorSmallCircle}
                                        pointerRadius={0}
                                        initialAnimation={true}
                                    >
                                        <div className='indicator'
                                            data-aos="flip-right"
                                            data-aos-delay="600">
                                            <b>
                                                Ant Design
                                            </b>
                                        </div>
                                    </ProgressBar>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-12 mt-4" align="center">
                            <hr />
                            <ProgressBar
                                radius={100}
                                progress={100}
                                initialAnimation
                                initialAnimationDelay={10}
                                strokeWidth={4}
                                strokeColor={colorTallCircle}
                                trackStrokeWidth={4}
                                pointerRadius={8}
                                pointerStrokeWidth={5}
                                pointerStrokeColor={colorSmallCircle}
                            >
                                <div className='indicator'
                                    data-aos="flip-left">
                                    <b>
                                        Database
                                    </b>
                                </div>
                            </ProgressBar>
                            <div class="row">
                                <div class="col-xl col-lg-6 col-sm-12">
                                    <ProgressBar
                                        radius={50}
                                        progress={100}
                                        strokeWidth={12}
                                        strokeColor={colorSmallCircle}
                                        trackStrokeWidth={8}
                                        trackStrokeColor={colorSmallCircle}
                                        pointerRadius={0}
                                        initialAnimation={true}
                                    >
                                        <div className='indicator'
                                            data-aos="flip-right"
                                            data-aos-delay="200">
                                            <b>
                                                SQL/MYSQL
                                            </b>
                                        </div>
                                    </ProgressBar>
                                </div>
                                <div class="col-xl col-lg-6 col-sm-12">
                                    <ProgressBar
                                        radius={50}
                                        progress={100}
                                        strokeWidth={12}
                                        strokeColor={colorSmallCircle}
                                        trackStrokeWidth={8}
                                        trackStrokeColor={colorSmallCircle}
                                        pointerRadius={0}
                                        initialAnimation={true}
                                    >
                                        <div className='indicator'
                                            data-aos="flip-right"
                                            data-aos-delay="400">
                                            <b>
                                                MongoDB
                                            </b>
                                        </div>
                                    </ProgressBar>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        </section>
    )
}

export default Skills
